//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppMain',
  computed: {
    cachedViews: function cachedViews() {
      //  console.log(this.$store.state.tagsView.cachedViews, "缓存组件")
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  }
};