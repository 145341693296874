import { login as _login, logout as _logout, getInfo as _getInfo } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";
import md5 from "js-md5";
var getDefaultState = function getDefaultState() {
  return {
    token: getToken(),
    name: "",
    avatar: "",
    nickName: ""
  };
};
//sessionStorage.getItem('state') ? JSON.parse(sessionStorage.getItem('state')) :
var state = sessionStorage.getItem("userState") ? JSON.parse(sessionStorage.getItem("userState")) : getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, sessionId) {
    state.sessionId = sessionId;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_NICKNAME: function SET_NICKNAME(state, nickName) {
    state.nickName = nickName;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        loginName: username.trim(),
        password: md5(password)
      }).then(function (response) {
        var result = response.result;
        commit("SET_TOKEN", result.sessionId);
        commit("SET_NICKNAME", result.loginName);
        setToken(result.sessionId);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _getInfo(state.sessionId).then(function (response) {
        var data = response.data;
        if (!data) {
          reject("验证失败，请重新登录！！");
        }
        var name = data.name,
          avatar = data.avatar;
        commit("SET_NAME", name);
        commit("SET_AVATAR", avatar);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _logout(state.sessionId).then(function () {
        removeToken(); // must remove  token  first
        resetRouter();
        commit("RESET_STATE");
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};