import Cookies from "js-cookie";
var TokenKey = "operation_platform_token"; //    vue_admin_template_token

export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(sessionId) {
  return Cookies.set(TokenKey, sessionId);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}