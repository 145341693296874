// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"menuText": "#353535",
	"menuActiveText": "#2D9CFF",
	"subMenuActiveText": "#FFFFFF",
	"menuBg": "#F8F9FD",
	"menuHover": "#FFFFFF",
	"subMenuBg": "#003F76",
	"subMenuHover": "#08396F",
	"sideBarWidth": "220px"
};
module.exports = exports;
