var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    {
      ref: "scrollContainer",
      staticClass: "scroll-container",
      attrs: { vertical: false },
      nativeOn: {
        wheel: function($event) {
          $event.preventDefault()
          return _vm.handleScroll($event)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-loader/node_modules/vue-hot-reload-api")      .rerender("data-v-19861829", { render: render, staticRenderFns: staticRenderFns })
  }
}