var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      !_vm.isCollapse
        ? _c("div", { staticClass: "scrollbar-logo" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("el-scrollbar", { attrs: { "wrap-class": "scrollbar-wrapper" } }, [
        _c(
          "div",
          [
            _c(
              "el-menu",
              {
                attrs: {
                  "default-active": _vm.activeMenu,
                  collapse: _vm.isCollapse,
                  "background-color": _vm.variables.menuBg,
                  "text-color": _vm.variables.menuText,
                  "unique-opened": false,
                  "active-text-color": _vm.variables.menuActiveText,
                  "collapse-transition": false,
                  mode: "vertical"
                }
              },
              _vm._l(_vm.routes, function(route) {
                return _c("sidebar-item", {
                  key: route.path,
                  attrs: { item: route, "base-path": route.path }
                })
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "login-box" },
          [
            _c(
              "div",
              { staticClass: "avatar-box", on: { click: _vm.goToPersonal } },
              [
                _c("img", {
                  staticClass: "avatar",
                  attrs: { src: _vm.avatar, alt: "" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "username" }, [
              _vm._v(_vm._s(_vm.aliasName))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "logout-box", on: { click: _vm.logout } },
              [
                _c("span", { staticClass: "logout-icon" }),
                _vm._v(" "),
                _c("span", { staticClass: "logout-label" }, [_vm._v("退出")])
              ]
            ),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/userGuide" } }, [
              _c("div", { staticClass: "guide" }, [_vm._v("用户指南")])
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "logp-img",
        attrs: {
          src: require("../../../assets/home/logo-feixiao.png"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-loader/node_modules/vue-hot-reload-api")      .rerender("data-v-16bf9348", { render: render, staticRenderFns: staticRenderFns })
  }
}