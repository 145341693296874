var vueClearIndex = {};
vueClearIndex.install = function (Vue) {
  Vue.directive('clear-tooltip', {
    bind: function bind(el, binding, vnode) {
      el.__vueSetTimeoutIndex__ = setTimeout(function () {
        el.removeAttribute('tabindex');
        clearTimeout(el.__vueSetTimeoutIndex__);
      });
    },
    unbind: function unbind(el) {
      clearTimeout(el.__vueSetTimeoutIndex__);
    }
  });
};
export default vueClearIndex;